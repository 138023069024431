/* General App styling */
.App {
  text-align: center;
  background-color: #0e0e0e; /* Dark background */
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Styling for the 'Coming Soon' text with glitch effect */
.coming-soon {
  font-family: 'Exo 2', sans-serif; /* Example fancy font */
  font-size: 24px;
  font-weight: lighter;
  color: gray; /* Color of the text */
  position: relative; /* Add position relative to create a reference for pseudo-elements */
  overflow: hidden; /* Hide overflow to contain the glitch effect */
}

